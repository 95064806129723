
























































































































































































































































































































































































import Vue from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { unselectFirstElement, duplicateDataItems } from '@/helper/functions';
import { exportToPDF, saveZusammenfassungPDF } from '@/helper/handle-pdf';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';

const variantItem = {
  haftzeit: '',
  haftzeitSelect: '',
  zeitlicheSelbstbeteiligung: '',
  zeitlicheSelbstbeteiligungSelect: '',
  weiterfutterungskosten: '',
};

const versicherteLeistungenItem = {
  isSelected: false,
  num: 0,
  opened: true,
  openedVariants: true,
  versicherteLeistungen: {
    versicherteLeistung: '',
    versicherungssumme: null,
    ausfallzifferEinzelausfall: null,
    ausfallzifferDoppelausfall: null,
    ausfallzifferDreifachausfall: null,
    Beitragssatz: null,
  },
  versicherteLeistungenVariants: {
    variant1: {
      ...variantItem,
    },
    variant2: {
      ...variantItem,
    },
    variant3: {
      ...variantItem,
    },
  },
};

const positionenItem = {
  isSelected: false,
  num: 0,
  versichertePositionen: {
    versicherteMaschine: '',
    MKZ: '',
  },
  versicherteLeistungenItems: [
    versicherteLeistungenItem,
  ],
};

const positionenItemType = 1;

const versicherteLeistungenType = 2;

const fullTabItem = {
  opened: true,
  angebotserfassungBU: {
    eingabelogik: '',
  },
  gesamtversicherungssummen: [
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: '',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: '',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: '',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: '',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: '',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: '',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: '',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: '',
      },
      versicherteLeistungenValue: '',
    },
  ],
  positionenItems: [
    positionenItem,
  ],
};

export default Vue.extend({
  name: 'biogas-insurance-risk',
  mixins: [dataChangeMixin],
  components: {
    vSelect,
  },
  data() {
    return {
      name: 'biogas-insurance-risk',
      key: 'BIOGAS_INSURANCE',
      backUrl: 'biogas-insurance-tarif',
      gesamtversicherungssummenValues: [
        {
          value: 'Betriebsgewinn und fortlaufende Kosten',
          label: 'Betriebsgewinn und fortlaufende Kosten',
        },
        {
          value: 'Vertragsstrafen /Pönalen',
          label: 'Vertragsstrafen /Pönalen',
        },
        {
          value: 'Zeitabhängige Mehrkosten',
          label: 'Zeitabhängige Mehrkosten',
        },
        {
          value: 'Zeitabhängige Mehrkosten Strombezug AP',
          label: 'Zeitabhängige Mehrkosten Strombezug AP',
        },
        {
          value: 'Zeitunabhängige Mehrkosten',
          label: 'Zeitunabhängige Mehrkosten',
        },
        {
          value: 'Zeitunabhängige Mehrkosten Strombezug LP',
          label: 'Zeitunabhängige Mehrkosten Strombezug LP',
        },
        {
          value: 'Entgangene Erlöse aus dem Stromverkauf',
          label: 'Entgangene Erlöse aus dem Stromverkauf',
        },
      ],
      tabItems: [
        JSON.parse(JSON.stringify(fullTabItem)),
      ],
      byPositionenItemType: positionenItemType,
      byVersicherteLeistungenType: versicherteLeistungenType,
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'id'],
  methods: {
    goToBackUrl() {
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 2);
    },
    addMainItem() {
      const item = JSON.parse(JSON.stringify(fullTabItem));
      this.tabItems.push({
        ...item,
      });
    },
    duplicateVersicherteLeistungenItemItem(index: number, positionenIndex: number, fillData: boolean) {
      duplicateDataItems(
        versicherteLeistungenItem,
        this.tabItems[index].positionenItems[positionenIndex].versicherteLeistungenItems,
        fillData,
      );
    },
    deleteVersicherteLeistungenItemItem(index: number, positionenIndex: number) {
      unselectFirstElement(this.tabItems[index].positionenItems[positionenIndex].versicherteLeistungenItems);

      this.tabItems[index].positionenItems[positionenIndex].versicherteLeistungenItems =
        this.tabItems[index].positionenItems[positionenIndex].versicherteLeistungenItems.filter((item) => !item.isSelected);
    },
    setValues(insuranceData: any) {
      const { riskFactorData } = insuranceData;

      if (riskFactorData) {
        this.gesamtversicherungssummenValues = riskFactorData.gesamtversicherungssummenValues;
        this.tabItems = riskFactorData.tabItems;
        this.byPositionenItemType = riskFactorData.byPositionenItemType;
        this.byVersicherteLeistungenType = riskFactorData.byVersicherteLeistungenType;
      }
    },
    onSubmit(goNextPage = true) {
      const data = {
        gesamtversicherungssummenValues: this.gesamtversicherungssummenValues,
        tabItems: this.tabItems,
        byPositionenItemType: this.byPositionenItemType,
        byVersicherteLeistungenType: this.byVersicherteLeistungenType,
      };
      this.$emit('risk-factor-data-change', data, goNextPage);
    },
    downloadPDF() {
      exportToPDF.call(this, 'Zusammenfassung-Biogas-Risikofaktoren.pdf');
    },
    savePdf() {
      saveZusammenfassungPDF.call(this, this.id, 'saveRiskPDF', this.onSubmit);
    },
    deleteRows(tabId: number, type: number) {
      switch (type) {
        case this.byPositionenItemType:
          unselectFirstElement(this.tabItems[tabId].positionenItems);

          this.tabItems[tabId].positionenItems = this.tabItems[tabId].positionenItems.filter((item) => !item.isSelected);
          break;
        default:
          console.log('Wrong type');
      }
    },
    dublicateRow(tabId: number, type: number, fillData: boolean) {
      switch (type) {
        case this.byPositionenItemType:
          duplicateDataItems(positionenItem, this.tabItems[tabId].positionenItems, fillData);
          break;
        default:
          console.log('Wrong type');
      }
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData) {
      this.setValues(this.insuranceData);
    }
    (this as any).startWatcherActivated();
  },
});
